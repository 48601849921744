export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは8月より%d点上がりました。季節の変わり目も良い生活習慣ができていますね。<br>
      10月7日からWalk 個人チャレンジ 2022 2ndが開催。<b>10月6日までエントリー受付！</b><br>
      楽しみながら歩けるちょっとした工夫をご紹介♪<br>
      ・風景や花々を見たり写真を撮りながら歩く<br>
      ・好きな音楽を聞きながら歩く<br>
      ・お気に入りのシューズやウェアを準備`,
      down: `生活習慣スコアは8月より%d点下がりました。分類別評価でどの項目に変化があったのか振り返ってみましょう。<br>
      10月7日からWalk 個人チャレンジ 2022 2ndが開催。<b>10月6日までエントリー受付！</b><br>
      楽しみながら歩けるちょっとした工夫をご紹介♪<br>
      ・風景や花々を見たり写真を撮りながら歩く<br>
      ・好きな音楽を聞きながら歩く<br>
      ・お気に入りのシューズやウェアを準備`,
      equal100: `生活習慣スコアは8月に引き続き<span class="good">満点</span>です。良い習慣が継続できていて大変素晴らしいです！<br>
      10月7日からWalk 個人チャレンジ 2022 2ndが開催。<b>10月6日までエントリー受付！</b><br>
      楽しみながら歩けるちょっとした工夫をご紹介♪<br>
      ・風景や花々を見たり写真を撮りながら歩く<br>
      ・好きな音楽を聞きながら歩く<br>
      ・お気に入りのシューズやウェアを準備`,
      equal: `生活習慣スコアは8月と同じ点数です。分類別評価で項目ごとに振り返ってみましょう。<br>
      10月7日からWalk 個人チャレンジ 2022 2ndが開催。<b>10月6日までエントリー受付！</b><br>
      楽しみながら歩けるちょっとした工夫をご紹介♪<br>
      ・風景や花々を見たり写真を撮りながら歩く<br>
      ・好きな音楽を聞きながら歩く<br>
      ・お気に入りのシューズやウェアを準備`,
    },
    steps: {
      up: `歩数スコアは8月より%d点上がりました。<br>
      歩数が増えていますね。この調子です！<br>
      こまめにストレッチをすることを忘れずに♪`,
      down: `歩数スコアは8月より%d点下がりました。<br>
      通勤時や買い物に行く時に少し遠回りをしたり、階段を使ったりなど、日常生活で歩数を増やすチャンスがないか探してみましょう。`,
      equal: `歩数スコアは8月と同じです。<br>
      通勤時や買い物に行く時に少し遠回りをしたり、階段を使ったりなど、日常生活で歩数を増やすチャンスがないか探してみましょう。`,
    },
    exercise: {
      up: `運動のスコアは8月より%d点上がりました。<br>
      運動継続のポイントは<b>”楽しく続けられる”</b>ことです。  無理のない範囲で楽しみましょう♪`,
      down: `運動のスコアは8月より%d点下がりました。<br>
      １回30秒ずつでも、身体を動かす習慣のない人にとっては立派な運動になり、こり・むくみの改善などの効果も期待できますよ。＜<a href="https://go.andwell.jp/library/b8688c0f-fd90-11ec-bff1-06d158a16ffc">30秒のストレッチを紹介</a>＞`,
      equal: `運動のスコアは8月と同じです。<br>
      １回30秒ずつでも、身体を動かす習慣のない人にとっては立派な運動になり、こり・むくみの改善などの効果も期待できますよ。＜<a href="https://go.andwell.jp/library/b8688c0f-fd90-11ec-bff1-06d158a16ffc">30秒のストレッチを紹介</a>＞`,
    },
    meal: {
      up: `食事のスコアは8月より%d点上がりました。<br>
      秋に旬を迎えるキノコには、水に溶ける栄養素も多く含まれています。スープなど、水に溶けだした栄養素を一緒に食べられる料理にするのもオススメ。`,
      down: `食事のスコアは8月より%d点下がりました。<br>
      秋に旬を迎えるキノコには、水に溶ける栄養素も多く含まれています。スープなど、水に溶けだした栄養素を一緒に食べられる料理にするのもオススメ。`,
      equal: `食事のスコアは8月と同じです。<br>
      秋に旬を迎えるキノコには、水に溶ける栄養素も多く含まれています。スープなど、水に溶けだした栄養素を一緒に食べられる料理にするのもオススメ。`,
    },
    drinking: {
      up: `飲酒のスコアは8月より%d点上がりました。<br>
      自分のペースでゆっくりと楽しみながらお酒を飲みましょう。他人と話したり、食べながら飲むのも良いですね。`,
      down: `飲酒のスコアは8月より%d点下がりました。<br>
      自分のペースでゆっくりと楽しみながらお酒を飲みましょう。他人と話したり、食べながら飲むのも良いですね。`,
      equal: `飲酒のスコアは8月と同じです。<br>
      自分のペースでゆっくりと楽しみながらお酒を飲みましょう。他人と話したり、食べながら飲むのも良いですね。`,
    },
    sleep: {
      up: `睡眠のスコアは8月より%d点上がりました。<br>
      昼寝は午後の眠気を解消し活力を与えてくれます。<b>15分くらい</b>を目安にしましょう。`,
      down: `睡眠のスコアは8月より%d点下がりました。<br>
      昼寝は午後の眠気を解消し活力を与えてくれます。<b>15分くらい</b>を目安にしましょう。`,
      equal: `睡眠のスコアは8月と同じです。<br>
      昼寝は午後の眠気を解消し活力を与えてくれます。<b>15分くらい</b>を目安にしましょう。`,
    },
    stress: {
      up: `ストレスのスコアは8月より%d点上がりました。<br>
      ストレスを減らす行動「３つのR」をご存じですか？<br>
      ①レスト：意識的に休憩時間を。<br>
      ②レクリエーション：趣味の時間を。<br>
      残り1つは＜<a href="https://go.andwell.jp/library/874110d5-bae8-11ec-b99b-0af363eb5062">コラム</a>＞で確認♪
      `,
      down: `ストレスのスコアは8月より%d点下がりました。<br>
      ストレスを減らす行動「３つのR」をご存じですか？<br>
      ①レスト：意識的に休憩時間を。<br>
      ②レクリエーション：趣味の時間を。<br>
      残り1つは＜<a href="https://go.andwell.jp/library/874110d5-bae8-11ec-b99b-0af363eb5062">コラム</a>＞で確認♪`,
      equal: `ストレスのスコアは8月と同じです。<br>
      ストレスを減らす行動「３つのR」をご存じですか？<br>
      ①レスト：意識的に休憩時間を。<br>
      ②レクリエーション：趣味の時間を。<br>
      残り1つは＜<a href="https://go.andwell.jp/library/874110d5-bae8-11ec-b99b-0af363eb5062">コラム</a>＞で確認♪`,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up %d pts since August. You're keeping up a good lifestyle at the turn of the season.<br>
      The Walk Individual Challenge 2022 2nd Round will be held from October 7. <b>Accepting entries until October 6!</b> <br>
      Here are a few tips for making your walks fun!<br>
      ・Admire the scenery and flowers and take pictures of them while walking<br>
      ・Listen to your favorite music while walking<br>
      ・Lay out your favorite activewear and shoes`,
      down: `Your lifestyle score has gone down %d pts since August. Use the categorized ratings to see what items have changed.<br>
      The Walk Individual Challenge 2022 2nd Round will be held from October 7. <b>Accepting entries until October 6!</b> <br>
      Here are a few tips for making your walks fun!<br>
      ・Admire the scenery and flowers and take pictures of them while walking<br>
      ・Listen to your favorite music while walking<br>
      ・Lay out your favorite activewear and shoes`,
      equal100: `Your lifestyle score is the <span class="good">maximum</span> again, the same as August. You're keeping up a great lifestyle! Wonderful job!<br>
      The Walk Individual Challenge 2022 2nd Round will be held from October 7. <b>Accepting entries until October 6!</b> <br>
      Here are a few tips for making your walks fun!<br>
      ・Admire the scenery and flowers and take pictures of them while walking<br>
      ・Listen to your favorite music while walking<br>
      ・Lay out your favorite activewear and shoes`,
      equal: `Your lifestyle score is the same as August. Use the categorized ratings to review your progress item by item.<br>
      The Walk Individual Challenge 2022 2nd Round will be held from October 7. <b>Accepting entries until October 6!</b> <br>
      Here are a few tips for making your walks fun!<br>
      ・Admire the scenery and flowers and take pictures of them while walking<br>
      ・Listen to your favorite music while walking<br>
      ・Lay out your favorite activewear and shoes`,
    },
    steps: {
      up: `Your steps score has gone up %d pts since August.<br>
      Your steps are increasing. Keep it up! <br>
      Remember to stretch now and then!`,
      down: `Your steps score has gone down %d pts since August. <br>
      Look for opportunities to increase your steps in your daily life, such as taking little detours while commuting to work or going shopping, or taking the stairs.`,
      equal: `Your steps score is the same as August. <br>
      Look for opportunities to increase your steps in your daily life, such as taking little detours while commuting to work or going shopping, or taking the stairs.`,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since August.<br>
      The key to sticking to exercise is <b>to make it enjoyable and easy to keep up with</b>.  Have fun and don't overdo it!`,
      down: `Your exercise score has gone down %d pts since August.<br>
      Even 30 seconds at a time can be great exercise for people who don't routinely move their bodies,
      and it can help improve stiffness and swelling. ＜<a href="https://go.andwell.jp/library/b8688c0f-fd90-11ec-bff1-06d158a16ffc">Introducing 30-second stretches</a>＞`,
      equal: `Your exercise score is the same as August.<br>
      Even 30 seconds at a time can be great exercise for people who don't routinely move their bodies,
      and it can help improve stiffness and swelling. ＜<a href="https://go.andwell.jp/library/b8688c0f-fd90-11ec-bff1-06d158a16ffc">Introducing 30-second stretches</a>＞`,
    },
    meal: {
      up: `Your food score has gone up %d pts since August.<br>
      A sign that fall is here, mushrooms are rich in water-soluble nutrients.
      Soups and other dishes that supply you with the nutrients dissolved in the water are a great way to get them in.`,
      down: `Your food score has gone down %d pts since August.<br>
      A sign that fall is here, mushrooms are rich in water-soluble nutrients.
      Soups and other dishes that supply you with the nutrients dissolved in the water are a great way to get them in.`,
      equal: `Your food score is the same as August.<br>
      A sign that fall is here, mushrooms are rich in water-soluble nutrients.
      Soups and other dishes that supply you with the nutrients dissolved in the water are a great way to get them in.`,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since August.<br>
      Enjoy drinks at your own pace. It's also good to chat with people and drink while eating.`,
      down: `Your alcohol score has gone down %d pts since August.<br>
      Enjoy drinks at your own pace. It's also good to chat with people and drink while eating.`,
      equal: `Your alcohol score is the same as August.<br>
      Enjoy drinks at your own pace. It's also good to chat with people and drink while eating.`,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since August.<br>
      A power nap will relieve you of that afternoon drowsiness and revitalize you. <b>About 15 minutes</b> is ideal.`,
      down: `Your sleep score has gone down %d pts since August.<br>
      A power nap will relieve you of that afternoon drowsiness and revitalize you. <b>About 15 minutes</b> is ideal.`,
      equal: `Your sleep score is the same as August.<br>
      A power nap will relieve you of that afternoon drowsiness and revitalize you. <b>About 15 minutes</b> is ideal.`,
    },
    stress: {
      up: `Your stress score has gone up %d pts since August.<br>
      Do you know the 3 R's for reducing stress? <br>
      (1) Rest: Consciously take a break. <br>
      (2) Recreation: Make time for your hobbies. <br>
      Find out what the last one is in the <a href="https://go.andwell.jp/library/874110d5-bae8-11ec-b99b-0af363eb5062">column</a>!`,
      down: `Your stress score has gone down %d pts since August.<br>
      Do you know the 3 R's for reducing stress? <br>
      (1) Rest: Consciously take a break. <br>
      (2) Recreation: Make time for your hobbies. <br>
      Find out what the last one is in the <a href="https://go.andwell.jp/library/874110d5-bae8-11ec-b99b-0af363eb5062">column</a>!`,
      equal: `Your stress score is the same as August.<br>
      Do you know the 3 R's for reducing stress? <br>
      (1) Rest: Consciously take a break. <br>
      (2) Recreation: Make time for your hobbies. <br>
      Find out what the last one is in the <a href="https://go.andwell.jp/library/874110d5-bae8-11ec-b99b-0af363eb5062">column</a>!`,
    },
  },
}
